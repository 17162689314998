/* CSS Reset */
/* https://piccalil.li/blog/a-modern-css-reset */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@font-face {
  font-family: "ChickenSans-Bold";
  src: local("ChickenSans-Bold"),
    url("./assets/fonts/ChickenSans-Bold.woff") format("woff"),
    url("./assets/fonts/ChickenSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "ChickenSans-Regular";
  src: local("ChickenSans-Regular"),
    url("./assets/fonts/ChickenSans-Regular.woff") format("woff"),
    url("./assets/fonts/ChickenSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ChickenSans-ExtraLight";
  src: local("ChickenSans-ExtraLight"),
    url("./assets/fonts/ChickenSans-ExtraLight.woff") format("woff"),
    url("./assets/fonts/ChickenSans-ExtraLight.woff2") format("woff2");
}

@font-face {
  font-family: "ChickenScript-Regular";
  src: local("ChickenScript-Regular"),
    url("./assets/fonts/ChickenScript_Regular-VF.woff") format("woff"),
    url("./assets/fonts/ChickenScript_Regular-VF.woff2") format("woff2");
}

body {
  background-color: #000;
  font-family: "ChickenSans-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
}

*:disabled {
  cursor: auto !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.font-script {
  font-family: "ChickenScript-Regular", cursive;
  font-weight: 400;
}

.font-regular {
  font-family: "ChickenSans-Regular";
  font-weight: 400;
}

.shape {
  position: absolute;
  width: 200px;
  height: 200px;
  transform: scale(0.5);
  z-index: 1000;
  top: 0;
  left: 0;
}

.btn-particles {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  font-family: monospace;
  z-index: 1000;
}

.no-wrap {
  white-space: nowrap;
}

.input-range--disabled {
  pointer-events: none;
}

.input-range__min-label,
.input-range__max-label,
.input-range__value-label {
  display: none;
}

.centered {
  line-height: 1;
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.caps {
  text-transform: uppercase;
}

.bold-caps {
  font-family: "ChickenSans-Bold";
  font-weight: 700;
  text-transform: uppercase;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}


/* TURN OFF POPPY'S */
#TARGET1 *{
  fill: rgb(250,74,89, 0);
}

#TARGET2 *{
  fill: rgb(250,74,89, 0);
}

#TARGET3 *{
  fill: rgb(250,74,89, 0);
}

#TARGET4 *{
  fill: rgb(250,74,89, 0);
}

#TARGET5 *{
  fill: rgb(250,74,89, 0);
}

#TARGET6 *{
  fill: rgb(250,74,89, 0);
}

#TARGET7 *{
  fill: rgb(250,74,89, 0);
}

#TARGET8 *{
  fill: rgb(250,74,89, 0);
}

#TARGET9 *{
  fill: rgb(250,74,89, 0);
}

#TARGET10 *{
  fill: rgb(250,74,89, 0);
}

#TARGET11 *{
  fill: rgb(250,74,89, 0);
}

#TARGET12 *{
  fill: rgb(250,74,89, 0);
}

#TARGET13 *{
  fill: rgb(250,74,89, 0);
}

#TARGET14 *{
  fill: rgb(250,74,89, 0);
}

#TARGET15 *{
  fill: rgb(250,74,89, 0);
}

#TARGET16 *{
  fill: rgb(250,74,89, 0);
}

#TARGET17 *{
  fill: rgb(250,74,89, 0);
}

#TARGET18 *{
  fill: rgb(250,74,89, 0);
}

#TARGET19a *{
  fill: rgb(250,74,89, 0);
}

#TARGET19b *{
  fill: rgb(250,74,89, 0);
}

#TARGET20 *{
  fill: rgb(250,74,89, 0);
}
